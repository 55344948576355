import Vue from 'vue'
import VueRouter from 'vue-router'
import Top from "../views/Top"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/web/score/:scoreId',
    name: 'Notation',
    // component: Notation
    component: () => import('../views/Notation.vue')
  }
]



const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes

})


export default router
