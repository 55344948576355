<template>
  <v-container>
    <!-- <div> -->
    <v-row class="text-center mx-8">
      <!-- <v-row tify="center" class="text-center ma-8"> -->
      <v-col align="center" cols="12">
        <!-- <v-card flat color="pink" width="400"> -->
        <v-row>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            class="mt-0 pt-0"
          >
            <v-text-field
              hide-details
              class="mt-0 pt-0"
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              width="350"
              color="#e8b8cc"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" xl="2" lg="2" md="2" sm="6" xs="6" class="pa-0 pa-0">
            <v-btn small text @click="exportFile"
              ><v-icon>mdi-download</v-icon>Export backup</v-btn
            >
          </v-col>
          <v-col cols="6" xl="2" lg="2" md="2" sm="6" xs="6" class="pa-0 pa-0">
            <v-btn small text @click="this.showDialog">
              <v-icon>mdi-import</v-icon>
              import backup
            </v-btn>
            <InputDialog
              :inputDialog="this.inputDialog"
              @closeDialog="closeDialog"
              @importData="setScoreData"
            />
          </v-col>
          <v-col cols="12" sm="12" xs="12" class="hidden-md-and-up">
            <v-btn color="#da003a" block small outlined @click="newScore"
              ><v-icon small class="mr-1">mdi-music</v-icon> NEW SCORE</v-btn
            >
          </v-col>
        </v-row>
        <!-- </v-card> -->
      </v-col>
      <v-row class="text-center">
        <v-col align="center" cols="12">
          <MusicCard
            class="mt-4"
            :filterItem="this.items"
            @play="play"
            @pause="pause"
            @pushEdit="pushEdit"
            @trashCheck="trashCheck"
          />
        </v-col>
      </v-row>
    </v-row>
    <TrashCheckDialog
      :trashDialog="trashDialog"
      @stopTrash="stopTrash"
      @trash="trash"
    />
    <!-- </div> -->
  </v-container>
</template>

<script >
/* eslint-disable*/
import abcjs from "abcjs";
import "abcjs/abcjs-audio.css";
import InputDialog from "../components/InputDialog.vue";
import TrashCheckDialog from "../components/TrashCheckDialog.vue";
import MusicCard from "../components/MusicCard.vue";

export default {
  components: { abcjs, InputDialog, TrashCheckDialog, MusicCard },
  data() {
    return {
      scores: null,
      //画面に表示するitem
      items: [],
      scoreObj: {},
      scoreList: [],
      list: [],
      searchText: null,
      inputDialog: { dialog: false },
      trashDialog: { dialog: false, item: "" },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getLocalStorage();
      next();
    });
  },

  mounted() {},
  updated() {
    // for (let i in this.scores) {
    // this.makeScore(this.scores[i]);
    // }
    this.initial();
  },
  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(newValue) {
        this.items = [];
        for (let score of this.scores) {
          if (score.title.indexOf(newValue) != -1) {
            this.items.push(score);
          }
        }
      },
    },
  },
  methods: {
    play(item) {
      item.playFlg = false;
      let scoreId = item.scoreId;
      for (let score of this.scoreList) {
        if (scoreId == score["scoreId"]) {
          let midiBuffer = score["midiBuffer"];
          score["palyFlg"] = false;
          // midiBuffer.start();
          midiBuffer.prime().then(function (response) {
            midiBuffer.start();
          });
        }
      }
    },
    pause(item) {
      item.playFlg = true;
      let scoreId = item.scoreId;
      for (let score of this.scoreList) {
        if (scoreId == score["scoreId"]) {
          let midiBuffer = score["midiBuffer"];

          midiBuffer.prime().then(function (response) {
            midiBuffer.stop();
          });
        }
      }
    },
    initial() {
      for (let i in this.scores) {
        var obj = new Object();
        let score = this.scores[i];
        obj["scoreId"] = score.scoreId;

        let title = "\nT:" + score.title;
        let rhythm = "\nM:" + score.topNumber + "/" + score.bottomNumber;
        let standard_note = "\nL:" + score.standard_note;
        let key = "\nK:" + score.key.key;
        let bpm = "\nQ:" + score.tempo;
        let abc_score =
          "X:1" +
          title +
          rhythm +
          standard_note +
          key +
          bpm +
          "\n" +
          score.score;

        var visualObj = abcjs.renderAbc("*", abc_score)[0];
        var midiBuffer = new abcjs.synth.CreateSynth();

        midiBuffer.init({
          visualObj: visualObj,
          options: {},
        });

        obj["midiBuffer"] = midiBuffer;
        this.scoreList.push(obj);
      }
    },
    getLocalStorage() {
      this.scoreId = this.$route.params.scoreId;
      let scoresStr = localStorage.getItem("scores");
      if (scoresStr == null || scoresStr == undefined || scoresStr.length < 1) {
        return;
      }
      let parseResult = JSON.parse(scoresStr);
      for (var item of parseResult) {
        item.playFlg = true;
      }
      this.scores = parseResult;
      this.items = parseResult;
    },
    exportFile() {
      let scoresStr = localStorage.getItem("scores");
      const blob = new Blob([scoresStr], { type: "text/plain" });
      const aTag = document.createElement("a");
      aTag.href = URL.createObjectURL(blob);
      aTag.target = "_blank";
      aTag.download =
        "export_" +
        $DateTime.now().toFormat("yyyyMMddHHmmss").toString() +
        ".json";
      aTag.click();
      URL.revokeObjectURL(aTag.href);
    },
    pushEdit(scoreId) {
      this.$router
        .push({
          name: "Notation",
          params: { scoreId: scoreId },
        })
        .catch(() => {});
    },
    showDialog() {
      this.inputDialog.dialog = true;
    },
    closeDialog() {
      this.inputDialog.dialog = false;
    },
    setScoreData() {
      let scoresStr = localStorage.getItem("scores");
      this.scores = JSON.parse(scoresStr);
      this.items = JSON.parse(scoresStr);
    },
    trashCheck(item) {
      this.trashDialog.item = item;
      this.trashDialog.dialog = true;
    },
    stopTrash() {
      this.trashDialog.dialog = false;
      this.trashDialog.item = {};
    },
    trash(item) {
      this.trashDialog.dialog = false;
      let list = [];
      for (let score of this.scores) {
        if (score.scoreId != item.scoreId) {
          list.push(score);
        }
      }
      localStorage.setItem("scores", JSON.stringify(list));
      this.getLocalStorage();
    },
    newScore() {
      const uuid = this.generateUuid();
      let existListStr = localStorage.getItem("scores");
      let existList = JSON.parse(existListStr);

      if (
        existList == null ||
        existList == undefined ||
        existList.length <= 0
      ) {
        // まだリストがないときリスト作る
        existList = [];
      }
      let object = {};
      object["scoreId"] = uuid;
      object["score"] = "CCGG|AAG2|]";
      object["title"] = "no title";
      object["key"] = { text: "C", key: "C" };
      object["topNumber"] = "4";
      object["bottomNumber"] = "4";
      object["tempo"] = 120;
      object["standard_note"] = "1/4";
      object["type"] = "major";
      object["image"] = "5gn2soeAc40";

      existList.push(object);

      localStorage.setItem("scores", JSON.stringify(existList));

      this.$router
        .push({
          name: "Notation",
          params: { scoreId: uuid },
        })
        .catch(() => {});
    },
    generateUuid() {
      let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
      for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
          case "x":
            chars[i] = Math.floor(Math.random() * 16).toString(16);
            break;
          case "y":
            chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
            break;
        }
      }
      return chars.join("");
    },
  },
};
</script>

