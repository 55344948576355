<template>
  <v-row>
    <v-col
      v-for="(item, index) in items"
      :key="index"
      class="py-0"
      cols="12"
      md="3"
      lg="3"
      xl="2"
      sm="12"
      xs="12"
    >
      <!-- base -->
      <v-sheet height="300" width="250" color="white" class="mt-0 pt-0">
        <!-- info -->
        <v-card
          max-height="250"
          elevation="5"
          rounded="xl"
          style="position: relative; top: 40px"
        >
          <v-row no-gutters justify="center" clsss="ma-0 pa-0">
            <v-col cols="8" class="pa-0" style="position: relative; top: -30px">
              <!-- src="../assets/sample.jpg" -->
              <!-- :src="'https://source.unsplash.com/' + item.image" -->
              <v-img
                @click="pushEdit(item.scoreId)"
                src="/default_white.jpg"
                :aspect-ratio="29 / 23"
                class="ma-0 pa-0"
                style="border-radius: 20px 20px 20px 20px"
              />
            </v-col>
          </v-row>

          <!-- style="position: relative; top: 100px" -->
          <v-col class="py-0 my-0" align="left">
            <v-card-title class="py-0 my-0" @click="pushEdit(item.scoreId)">
              <p
                class="text-subtitle-1 font-weight-regula"
                style="
                  color: #767676;
                  max-width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.title }}
              </p>
            </v-card-title>
            <v-card-actions class="pt-0">
              <v-btn icon color="pink" v-if="item.playFlg" @click="play(item)">
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn icon color="pink" v-else @click="pause(item)">
                <v-icon>mdi-pause</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn icon color="pink" @click="trashCheck(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script >
/* eslint-disable*/
import abcjs from "abcjs";
import "abcjs/abcjs-audio.css";
// import TrashCheckDialog from "../components/TrashCheckDialog.vue";
export default {
  props: ["filterItem"],
  components: { abcjs },
  computed: {
    items: {
      get() {
        return this.filterItem;
      },
    },
  },
  methods: {
    play(item) {
      this.$emit("play", item);
    },
    pause(item) {
      this.$emit("pause", item);
    },
    pushEdit(scoreId) {
      this.$emit("pushEdit", scoreId);
    },
    trashCheck(item) {
      this.$emit("trashCheck", item);
    },
  },
};
</script>