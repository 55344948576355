<template>
  <v-navigation-drawer hide-overlay v-model="draw" app color="#d2c9d1">
    <v-row justify="center" class="text-center ma-8">
      <div>
        <!-- <v-img aspect-ratio="1" src="./Untitled.png"></v-img> -->
        <v-col align="center" cols="12">
          <v-img src="/icon_512.png" max-width="100" aspect-ratio="1"> </v-img>
        </v-col>

        <v-col class="mt-4" cols="12">
          <v-btn large text color="#da003a" style="width: 90%" @click="topPage">
            TOP PAGE
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            large
            text
            color="#da003a"
            style="width: 90%"
            @click="newScore"
          >
            NEW SCORE
          </v-btn>
        </v-col>

        <v-row justify="center">
          <v-col style="position: absolute; bottom: 10%">
            <v-col>
              <v-btn
                small
                elevation="1"
                color="#696569"
                href="https://twitter.com/kifujin_jp"
                target="_blank"
                rel="noopener noreferrer"
                outlined
              >
                <v-icon color="#da003a">mdi-twitter</v-icon>
                記譜人Twitter
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                small
                elevation="1"
                color="#696569"
                href="https://wavebox.me/wave/4prfntm6uni59i0t/"
                target="_blank"
                rel="noopener noreferrer"
                outlined
              >
                <v-icon color="#da003a">mdi-heart</v-icon>
                開発を応援する!
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
/* eslint-disable*/
import callApi from "../js/callApi";
export default {
  props: ["drawer"],
  // props: { drawer: { type: Boolean, default: false, required: true } },
  data() {
    return {
      right: null,
      drawFlg: false,
      alignments: ["end"],
    };
  },

  computed: {
    buttonBrakePoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
        case "xl":
          return "60%";
      }
    },
    draw: {
      get() {
        this.drawFlg = this.drawer;
        return this.drawFlg;
      },
      set(flg) {
        this.$emit("changeDraw", flg);
      },
    },
    getScoreId() {
      return this.$store.getters["score/scoreId"];
    },
  },
  methods: {
    topPage() {
      this.$router
        .push({
          name: "Top",
        })
        .catch(() => {});
    },
    newScore() {
      const uuid = this.generateUuid();
      let existListStr = localStorage.getItem("scores");
      let existList = JSON.parse(existListStr);

      if (
        existList == null ||
        existList == undefined ||
        existList.length <= 0
      ) {
        // まだリストがないときリスト作る
        existList = [];
      }
      let object = {};
      object["scoreId"] = uuid;
      object["score"] = "CCGG|AAG2|]";
      object["title"] = "no title";
      object["key"] = { text: "C", key: "C" };
      object["topNumber"] = "4";
      object["bottomNumber"] = "4";
      object["tempo"] = 120;
      object["standard_note"] = "1/4";
      object["type"] = "major";
      object["image"] = "5gn2soeAc40";

      existList.push(object);

      localStorage.setItem("scores", JSON.stringify(existList));

      this.$router
        .push({
          name: "Notation",
          params: { scoreId: uuid },
        })
        .catch(() => {});
    },
    generateUuid() {
      // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
      // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
      let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
      for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
          case "x":
            chars[i] = Math.floor(Math.random() * 16).toString(16);
            break;
          case "y":
            chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
            break;
        }
      }
      return chars.join("");
    },
  },
};
</script>