<template>
  <div>
    <v-dialog v-model="dialog" fullscreen transition="dialog-top-transition">
      <v-overlay opacity="0.98" color="primary">
        <v-row justify="center" class="text-center ma-8">
          <v-col cols="12">
            <v-btn large text color="white" @click="topPage"> TOP PAGE </v-btn>
          </v-col>
          <v-col cols="12" />
          <v-col cols="12" />
          <v-col>
            <v-btn
              small
              elevation="1"
              color="white"
              href="https://twitter.com/kifujin_jp"
              target="_blank"
              rel="noopener noreferrer"
              outlined
            >
              <v-icon color="#c42983" class="mr-1">mdi-twitter</v-icon>
              記譜人Twitter
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              small
              elevation="1"
              color="white"
              href="https://wavebox.me/wave/4prfntm6uni59i0t/"
              target="_blank"
              rel="noopener noreferrer"
              outlined
            >
              <v-icon color="#c42983" class="mr-1">mdi-heart</v-icon>
              開発を応援する!
            </v-btn>
          </v-col>
          <v-col cols="12" />
          <v-col cols="12" />
          <v-col cols="12">
            <v-btn icon outlined color="white" @click="this.close"
              ><v-icon color="white">mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable*/
export default {
  props: { menuDialog: { type: Object, default: () => ({ dialog: false }) } },
  computed: {
    dialog: {
      get() {
        return this.menuDialog.dialog;
      },
      set() {
        this.$emit("closeDialog");
      },
    },
  },
  methods: {
    topPage() {
      this.menuDialog.dialog = false;
      this.$router
        .push({
          name: "Top",
        })
        .catch(() => {});
    },
    close() {
      this.menuDialog.dialog = false;
    },
  },
};
</script>