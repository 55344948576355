import axios from 'axios'
const state = {
    // 現在のスコアのID
    scoreId: null,
    title: null,
    //調
    key: null,
    // テンポ
    tempo: null,
    //4分の[3拍子],分母
    topNumber: null,
    //[4分の]3拍子,分子
    bottomNumber: null,
    score: null,
    type: null,
    abcScore: null
}


const getters = {
    scoreId: state => state.scoreId,
    title: state => state.title,
    key: state => state.key,
    tempo: state => state.tempo,
    topNumber: state => state.topNumber,
    bottomNumber: state => state.bottomNumber,
    score: state => state.score,
    type: state => state.type,
    abcScore: state => state.abcScore
}

const actions = {
    getScoreId({ commit }) {
        //新規作成
        console.log("スコア新規作成")
        return new Promise((resolve, reject) => {
            axios.post("/score",)
                .then((response) => {
                    commit('getScoreId', response.data.scoreId)
                    return resolve(response)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    },
    getScoreInfo({ commit }, scoreId) {
        //スコア取得
        console.log("スコア取得")
        return new Promise((resolve, reject) => {
            axios.get("/score", { params: { scoreId: scoreId } })
                .then((response) => {
                    commit('getScoreInfo', response.data[0])
                    console.log(response.data[0])
                    return resolve(response)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    },
    // setScoreInfo({ commit }, scoreId){
    //     //スコアが変更されたら登録する
    // },
    setTitle({ commit }, title) {
        commit('setTitle', title)
    },
    setKey({ commit }, key) {
        commit('setKey', key)
    },
    setType({ commit }, type) {
        commit('setType', type)
    },
    setTempo({ commit }, tempo) {
        commit('setTempo', tempo)
    },
    setTopNumber({ commit }, topNumber) {
        commit('setTopNumber', topNumber)
    },
    setBottomNumber({ commit }, bottomNumber) {
        commit('setBottomNumber', bottomNumber)
    },
    setScore({ commit }, score) {
        commit('setScore', score)

    },
    setAbcScore() { }

}

const mutations = {
    getScoreId(state, scoreId) {
        state.scoreId = scoreId
    },
    getScoreInfo(state, data) {
        state.scoreId = data.scoreId
        state.title = data.title
        state.key = data.key
        state.tempo = data.tempo
        state.topNumber = data.topNumber
        state.bottomNumber = data.bottomNumber
        state.score = data.score
        state.type = data.type
    },
    setTitle(state, title) {
        state.title = title
    },
    setKey(state, key) {
        state.key = key
    },
    setType(state, type) {
        state.type = type
    },
    setTempo(state, tempo) {
        state.tempo = tempo
    },
    setTopNumber(state, topNumber) {
        state.topNumber = topNumber
    },
    setBottomNumber(state, bottomNumber) {
        state.bottomNumber = bottomNumber
    },
    setScore(state, score) {
        state.score = score
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}