<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-overlay :absolute="absolute" :value="overlay">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h5 grey lighten-2">
          スコアファイルのインポート
        </v-card-title>
        <v-col cols="10">
          <v-file-input
            type="file"
            v-model="file"
            accept="application/json"
            label="File input"
          ></v-file-input>
        </v-col>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="getFileContent">
            インポートを実行する
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            キャンセル
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable*/
export default {
  props: { inputDialog: { type: Object, default: () => ({ dialog: false }) } },
  data() {
    return {
      inputFile: [],
      absolute: true,
      overlay: false,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.inputDialog.dialog;
      },
      set() {
        this.$emit("closeDialog");
      },
    },
    file: {
      get() {
        return this.inputFile;
      },
      set(newValue) {
        this.inputFile = newValue;
      },
    },
  },
  methods: {
    async getFileContent() {
      var content = "";
      try {
        this.overlay = true;
        content = await this.readFileAsync(this.inputFile);
        await this.setDateAsync(content);
        this.overlay = false;
      } catch (e) {
        alert("ファイルのインポートに失敗しました");
        return;
      } finally {
        this.inputFile = [];
        this.overlay = false;
        this.dialog = false;
      }
      this.$emit("importData");
      //   alert("インポートしました");

      return;
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });
    },
    setDateAsync(content) {
      let self = this;
      return new Promise((resolve, reject) => {
        let parseInputScores = "";
        try {
          parseInputScores = JSON.parse(content);
        } catch (e) {
          this.inputFile = [];
          this.dialog = false;
          alert("ファイルの内容が壊れています。\n" + e);
          return;
        }

        // バリデーションチェック
        for (var score of parseInputScores) {
          let result = self.fileValid(score);
          if (!result) {
            this.inputFile = [];
            this.dialog = false;
            alert("ファイルの内容が壊れています");
            return;
          }
        }

        let scoresStr = localStorage.getItem("scores");

        if (scoresStr != "" && scoresStr != null && scoresStr != undefined) {
          const parsSecores = JSON.parse(scoresStr);

          for (var item of parseInputScores) {
            parsSecores.push(item);
          }
          localStorage.setItem("scores", JSON.stringify(parsSecores));
        } else {
          localStorage.setItem("scores", content);
        }
        // 成功
        setTimeout(() => {
          resolve("");
        }, 1000);
      });
    },
    fileValid(score) {
      // エラーの時falseを返す
      if (!("scoreId" in score)) {
        return false;
      }
      if (
        score.scoreId == "" ||
        score.scoreId == null ||
        score.scoreId == undefined
      ) {
        return false;
      }
      if (!("score" in score)) {
        return false;
      }
      if (
        score.score == "" ||
        score.score == null ||
        score.score == undefined
      ) {
        return false;
      }
      if (!("key" in score)) {
        return false;
      }
      if (score.key == "" || score.key == null || score.key == undefined) {
        return false;
      }
      if (!("type" in score)) {
        return false;
      }
      if (score.type == "" || score.type == null || score.type == undefined) {
        return false;
      }
      if (!("topNumber" in score)) {
        return false;
      }
      if (
        score.topNumber == "" ||
        score.topNumber == null ||
        score.topNumber == undefined
      ) {
        return false;
      }
      if (!("bottomNumber" in score)) {
        return false;
      }
      if (
        score.bottomNumber == "" ||
        score.bottomNumber == null ||
        score.bottomNumber == undefined
      ) {
        return false;
      }
      if (!("tempo" in score)) {
        return false;
      }
      if (
        score.tempo == "" ||
        score.tempo == null ||
        score.tempo == undefined
      ) {
        return false;
      }
      if (!("title" in score)) {
        return false;
      }
      if (
        score.title == "" ||
        score.title == null ||
        score.title == undefined
      ) {
        return false;
      }
      if (!("standard_note" in score)) {
        return false;
      }
      if (
        score.standard_note == "" ||
        score.standard_note == null ||
        score.standard_note == undefined
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
