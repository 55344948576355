<template>
  <v-app>
    <header>
      <!-- <div class="hidden-sm-and-down"> -->
      <div>
        <div v-if="!isMobile">
          <Header />
        </div>
        <div v-if="isMobile">
          <HeaderMobile />
        </div>
      </div>
    </header>
    <v-main>
      <div>
        <router-view />
      </div>
      <!-- <div class="hidden-md-and-up"><ComingSoon /></div> -->
    </v-main>
  </v-app>
</template>


<script>
/* eslint-disable*/
import Header from "./components/pc/Header.vue";
import HeaderMobile from "./components/mobile/HeaderMobile.vue";
// import ComingSoon from "./views/ComingSoon";

export default {
  components: { Header, HeaderMobile },
  // components: { Header, ComingSoon },
  name: "App",
  data: () => ({
    //
  }),

  computed: {
    isMobile() {
      const mobileBreakpoints = ["xs", "sm"];
      return mobileBreakpoints.some((e) => {
        return this.$vuetify.breakpoint.name === e;
      });
    },
  },
};
</script>
