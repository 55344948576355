const state = {
    items: [],
}


const getters = {
    items: state => state.items,
    score: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["score"];
            }
        }
        return ""
    },
    title: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["title"];
            }
        }
        return ""
    },
    key: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["key"];
            }
        }
        return ""
    },
    tempo: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["tempo"];
            }
        }
        return ""
    },
    standard_note: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["standard_note"];
            }
        }
        return ""
    },
    topNumber: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["topNumber"];
            }
        }
        return ""
    },
    bottomNumber: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["bottomNumber"];
            }
        }
        return ""
    },
    type: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["type"];
            }
        }
        return ""
    },
    image: state => (scoreId) => {
        for (var item of state.items) {
            if (item["scoreId"] == scoreId) {
                return item["image"];
            }
        }
        return ""
    },
    // score(state, scoreId) {
    //     console.log("引数！！:" + scoreId)
    //     return state.item
    // }
}

const actions = {
    setItems({ commit }) {
        commit('setItems')
    },
    setScore({ commit }, score) {
        commit('setScore', score)
    },
    setTitle({ commit }, title) {
        commit('setTitle', title)
    },
    setKey({ commit }, key) {
        commit('setKey', key)
    },
    setTempo({ commit }, tempo) {
        commit('setTempo', tempo)
    },
    setStandard_note({ commit }, standard_note) {
        commit('setStandard_note', standard_note)
    },
    setTopNumber({ commit }, topNumber) {
        commit('setTopNumber', topNumber)
    },
    setBottomNumber({ commit }, bottomNumber) {
        commit('setBottomNumber', bottomNumber)
    },
    setType({ commit }, type) {
        commit('setType', type)
    },
    setImage({ commit }, image) {
        commit('setImage', image)
    },

}
const mutations = {
    setItems(state) {
        let scoresStr = localStorage.getItem("scores");
        state.items = JSON.parse(scoresStr);
    },
    setScore({ commit }, score) {
        commit('setScore', score)
    },
    setTitle({ commit }, title) {
        commit('setTitle', title)
    },
    setKey({ commit }, key) {
        commit('setKey', key)
    },
    setTempo({ commit }, tempo) {
        commit('setTempo', tempo)
    },
    setStandard_note({ commit }, standard_note) {
        commit('setStandard_note', standard_note)
    },
    setTopNumber({ commit }, topNumber) {
        commit('setTopNumber', topNumber)
    },
    setBottomNumber({ commit }, bottomNumber) {
        commit('setBottomNumber', bottomNumber)
    },
    setType({ commit }, type) {
        commit('setType', type)
    },
    setImage({ commit }, image) {
        commit('setImage', image)
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}