<template>
  <div>
    <v-app-bar height="40" dense flat app color="white">
      <v-toolbar-title
        class="text-h6 pa-0"
        style="color: #b9b8b7"
        @click="pushPage"
      >
        <v-container>
          <v-img src="/betatitle.svg" max-width="120"> </v-img>
        </v-container>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn class="mr-0" icon @click="showDialog">
        <v-icon color="#da003a">mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <MenuMobile :menuDialog="this.menuDialog" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import MenuMobile from "./MenuMobile.vue";
export default {
  components: { MenuMobile },
  data() {
    return { menuDialog: { dialog: false } };
  },
  computed: {},
  methods: {
    showDialog() {
      this.menuDialog.dialog = true;
    },
    closeDialog() {
      this.menuDialog.dialog = false;
    },
    pushPage() {
      this.$router
        .push({
          name: "Top",
        })
        .catch(() => {});
    },
  },
};
</script>
