<template>
  <div>
    <v-app-bar height="40" dense flat app color="white">
      <!-- <v-app-bar-nav-icon @click="drawFlg = !drawFlg"></v-app-bar-nav-icon> -->

      <v-btn class="mr-0" icon @click="drawFlg = !drawFlg">
        <v-icon v-if="!drawFlg" color="#da003a">mdi-menu</v-icon>
        <v-icon v-else color="#da003a">mdi-close</v-icon>
      </v-btn>

      <v-toolbar-title
        class="text-h6 pa-0"
        style="color: #b9b8b7"
        @click="pushPage"
      >
        <v-container>
          <!-- <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0"> -->
          <v-img src="/betatitle.svg" max-width="120"> </v-img>
          <!-- </v-col>
          </v-row> -->
        </v-container>
        <!-- 記譜人<v-icon>mdi-beta</v-icon> -->
      </v-toolbar-title>
    </v-app-bar>

    <SelectList :drawer="this.drawFlg" @changeDraw="changeDraw" />
  </div>
</template>

<script>
import SelectList from "../SelectList.vue";

export default {
  components: { SelectList },
  data() {
    return { drawFlg: true };
  },
  computed: {
    // drawer() {
    //   return this.drawFlg;
    // },
    // drawer: {
    //   get() {
    //     return this.drawFlg;
    //   },
    //   set() {
    //     console("親");
    //     this.drawFlg = !this.drawFlg;
    //   },
    // },
  },
  methods: {
    changeDraw(flg) {
      if (this.drawFlg & flg) {
        return;
      }
      this.drawFlg = flg;
    },
    pushPage() {
      this.$router
        .push({
          name: "Top",
        })
        .catch(() => {});
    },
  },
};
</script>
