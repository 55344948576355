<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> 曲を削除します </v-card-title>
        <v-card-text>
          <b> {{ title }}</b>
          を削除します。<br />
          削除した曲はもとに戻せません。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="stopTrash"> やめる </v-btn>
          <v-btn color="green darken-1" text @click="trash"> 削除する </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  // props: ["trashDialog"],
  props: {
    trashDialog: {
      type: Object,
      default: () => ({ dialog: false, item: { title: "", scoreId: "" } }),
    },
  },

  computed: {
    dialog: {
      get() {
        return this.trashDialog.dialog;
      },
    },
    title: {
      get() {
        return this.trashDialog.item.title;
      },
    },
  },
  methods: {
    stopTrash() {
      //削除をやめる
      this.$emit("stopTrash");
    },
    trash() {
      //削除する
      this.$emit("trash", this.trashDialog.item);
    },
  },
};
</script>
