import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCodemirror from "vue-codemirror";
import { DateTime } from "luxon";
// import colors from 'vuetify/es5/util/colors';


// import "codemirror/lib/codemirror.css";
// import { Auth0Plugin } from "./auth";

// import { domain, clientId } from "../auth_config.json";

export let client = axios.create({
  baseURL: "",
});
window.$axios = client;
// Vue.prototype.$axios = axios


Vue.use(VueAxios, axios);
Vue.use(VueCodemirror);


window.$DateTime = DateTime

// Vue.use(Auth0Plugin, {
//   domain,
//   clientId,
//   onRedirectCallback: (appState) => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     );
//   },
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  // VueCodemirror,
  // abcjs,
  render: (h) => h(App),
}).$mount("#app");
