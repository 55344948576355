const state = {
    //ユーザーID
    userId: null,
    // scoreの一覧がはいるリスト
    items: []
}


const getters = {
    items: state => state.items,
    userId: state => state.userId,
}

const actions = {
    // setScoreList({ commit }, { userId, query }) {
    //     //ドキュメント一覧取得
    //     return new Promise((resolve, reject) => {
    //         $axios.get("/projects/" + projectId + "/documents", {
    //             params: query,
    //         })
    //             .then((response) => {
    //                 commit('setDocumentList', response.data)
    //                 return resolve(response)
    //             })
    //             .catch((error) => {
    //                 return reject(error)
    //             })
    //     })
    // },
}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}