import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import Vuetify from 'vuetify'

Vue.use(Vuetify);


export default new Vuetify({
    // rtl: true,
    theme: {
        // options: {
        //     customProperties: true,
        // },
        themes: {
            light: {
                primary: '#da003a',
                secondary: '#c42983',
                accent: '#3DB8AD',
                error: '#FF5252',
                info: '#696569',
                success: '#4CAF50',
                warning: '#FFC107',
                EzerRed: '#ed272d'
            },
        },
    },
    // lang: {
    // },
    // icons: {
    // },
});