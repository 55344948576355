import Vue from 'vue'
import Vuex from 'vuex'
import scoreList from './module/scoreList.js'
import score from './module/score.js'
import scoreListLocal from './module/scoreListLocal.js'
Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        scoreList,
        score,
        scoreListLocal
    },
    state: {

    },
    mutations: {

    },
    actions: {

    }
})